'use client';

import './input-birth-date.scss';

import cx from 'classix';
import { isValid } from 'date-fns';
import { useTranslations } from 'next-intl';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
// TODO: Use <Select /> component instead of import react-select directly
import Select, {
  type SelectInstance,
  type SingleValue,
} from 'react-select';
import { useIsMounted } from 'usehooks-ts';

import styles from '@/features/user/components/user-birthday-form/user-birthday-form.module.scss';
import {
  type UserBirthday,
  type UserBirthdayInputName,
} from '@/features/user/types/user-profile-types';
import { type UiSizesTypes } from '@/shared/types/ui-sizes-types';

export interface DateOption {
  label: number;
  value: number;
}

interface InputBirthDateProps {
  birthDate: Partial<UserBirthday> | null;
  onBirthDateChange: (inputName: UserBirthdayInputName, value: number) => void;
  onErrorsUpdate: (isError: boolean) => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
  size?: UiSizesTypes;
}

const currentYear = new Date().getFullYear();
const yearsOptions: DateOption[] = Array.from({ length: 103 }, (_, index) => {
  const mapYear = currentYear - 18 - index;
  return { label: mapYear, value: mapYear };
});
const monthsOptions: DateOption[] = Array.from({ length: 12 }, (_, index) => {
  const mapMonth = index + 1;
  return { label: mapMonth, value: mapMonth };
});

export default function InputBirthDate(props: InputBirthDateProps) {
  const {
    autoFocus,
    birthDate,
    isDisabled,
    onBirthDateChange,
    onErrorsUpdate,
    size,
  } = props;

  const translationsDates = useTranslations('global.date');
  const translationsErrors = useTranslations('global.form.errors');

  const isMounted = useIsMounted();

  const selectedYear = yearsOptions.find((year) => year.value === birthDate?.birth_year);
  const selectedMonth = monthsOptions.find((month) => month.value === birthDate?.birth_month);

  const getDaysInSelectedMonth = (month: number, year: number): number => new Date(year, month, 0).getDate();
  const daysOptions: DateOption[] = Array.from({ length: getDaysInSelectedMonth(birthDate?.birth_month ?? 1, birthDate?.birth_year ?? currentYear) }, (_, index) => {
    const mapDay = index + 1;
    return { label: mapDay, value: mapDay };
  });
  const selectedDay = daysOptions.find((day) => day.value === birthDate?.birth_day);
  const options = {
    birth_day: daysOptions,
    birth_month: monthsOptions,
    birth_year: yearsOptions,
  };

  const [birthDateError,
    setBirthDateError] = useState<string | null>(null);
  const [birthDateInputValue,
    setBirthDateInputValue] = useState({
    birth_day: birthDate?.birth_day ? String(birthDate.birth_day) : '',
    birth_month: birthDate?.birth_month ? String(birthDate.birth_month) : '',
    birth_year: birthDate?.birth_year ? String(birthDate.birth_year) : '',
  });
  const [filteredOptions,
    setFilteredOptions] = useState(options);
  const [isEditStart,
    setIsEditStart] = useState(false);

  const daysSelectRef = useRef<SelectInstance<DateOption>>(null);
  const monthsSelectRef = useRef<SelectInstance<DateOption>>(null);
  const yearsSelectRef = useRef<SelectInstance<DateOption>>(null);

  const checkIsDayValid = (year: number, month: number, day: number) => getDaysInSelectedMonth(month, year) >= day;

  const validateDate = (selectedBirthdate: Partial<UserBirthday>): void => {
    let isDateValid = true;

    if (selectedBirthdate.birth_year && selectedBirthdate.birth_month && selectedBirthdate.birth_day) {
      const fullDate = new Date(
        selectedBirthdate.birth_year,
        selectedBirthdate.birth_month - 1,
        selectedBirthdate.birth_day,
      );
      isDateValid = isValid(fullDate) && checkIsDayValid(selectedBirthdate.birth_year, selectedBirthdate.birth_month, selectedBirthdate.birth_day);
    }

    setBirthDateError(isDateValid ? null : translationsErrors('invalid-date'));
    onErrorsUpdate(!isDateValid);
  };

  const handleSelectChange = (inputName: UserBirthdayInputName) => (option: SingleValue<DateOption>): void => {
    if (option) {
      setIsEditStart(false);

      onBirthDateChange(inputName, option.value);

      if (inputName === 'birth_year') {
        monthsSelectRef.current?.focus();
      }

      if (inputName === 'birth_month') {
        daysSelectRef.current?.focus();
      }

      if (birthDate) {
        validateDate({
          ...birthDate,
          [inputName]: option.value,
        });
      }
    }
  };

  // Filter options as user types
  const handleInputChange = (inputName: UserBirthdayInputName) => (inputValue: string) => {
    const onlyNumbersRegex = /^\d+$/;

    if (onlyNumbersRegex.test(inputValue)) {
      setIsEditStart(true);

      setBirthDateInputValue({
        ...birthDateInputValue,
        [inputName]: inputValue,
      });

      const filtered = options[inputName].filter((option) => String(option.label).toLowerCase().includes(inputValue.toLowerCase()));

      setFilteredOptions({
        ...filteredOptions,
        [inputName]: filtered,
      });
    }

    if (inputValue === '') {
      setBirthDateInputValue({
        ...birthDateInputValue,
        [inputName]: '',
      });

      const filtered = options[inputName].filter((option) => String(option.label).toLowerCase().includes(inputValue.toLowerCase()));

      setFilteredOptions({
        ...filteredOptions,
        [inputName]: filtered,
      });
    }
  };

  // Handle menu close and pick the first option if available
  const handleMenuClose = (inputName: UserBirthdayInputName) => () => {
    if (filteredOptions[inputName].length > 0 && isEditStart) {
      handleSelectChange(inputName)(filteredOptions[inputName][0]);
    }
  };

  // const handleInputFocus = (inputName: UserBirthdayInputName) => () => {
  //   setBirthDateInputValue({ ...birthDateInputValue, [inputName]: String(birthDate?.[inputName] ?? '') });
  // };

  useEffect(() => {
    if (autoFocus) {
      yearsSelectRef.current?.focus();
    }
  }, [autoFocus, isMounted]);

  return (
    <div className={cx(styles.birthdayForm_birthdayFields, size && styles[`birthdayForm_birthdayFields__${size}`])}>
      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('year')}
        </div>

        <Select
          className={cx('birthdaySelect', size && `birthdaySelect__${size}`)}
          classNamePrefix="birthdaySelect"
          name="birth_year"
          ref={yearsSelectRef}
          value={selectedYear}
          inputValue={birthDateInputValue.birth_year}
          onChange={handleSelectChange('birth_year')}
          onInputChange={handleInputChange('birth_year')}
          onMenuClose={handleMenuClose('birth_year')}
          options={filteredOptions.birth_year}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('month')}
        </div>

        <Select
          className={cx('birthdaySelect', size && `birthdaySelect__${size}`)}
          classNamePrefix="birthdaySelect"
          name="birth_month"
          ref={monthsSelectRef}
          value={selectedMonth}
          inputValue={birthDateInputValue.birth_month}
          onChange={handleSelectChange('birth_month')}
          onInputChange={handleInputChange('birth_month')}
          onMenuClose={handleMenuClose('birth_month')}
          options={filteredOptions.birth_month}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('day')}
        </div>

        <Select
          className={cx('birthdaySelect', size && `birthdaySelect__${size}`)}
          classNamePrefix="birthdaySelect"
          name="birth_day"
          ref={daysSelectRef}
          value={selectedDay}
          inputValue={birthDateInputValue.birth_day}
          onChange={handleSelectChange('birth_day')}
          onInputChange={handleInputChange('birth_day')}
          onMenuClose={handleMenuClose('birth_day')}
          options={filteredOptions.birth_day}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      {birthDateError && (
        <div className={styles.birthdayForm_birthdayError}>
          {birthDateError}
        </div>
      )}
    </div>
  );
}
