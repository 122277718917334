import userIdVerificationAdapter from '@/features/user/requests/adapters/user-id-verification-adapter';
import { type UsersIdVerificationCollectionResponse } from '@/features/user/types/user-id-verification-response-types';
import { type UserIdVerification } from '@/features/user/types/user-id-verification-types';
import { type User } from '@/features/user/types/user-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

export default async function getUsersIdVerifications(usersIds: User['id'][]): Promise<UserIdVerification> {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v2/bgcheck/verification/${usersIds.join(',')}?filter=public`;
  const usersVerificationsResponse = await fetch(url, { cache: 'no-store' });

  // TODO: rewrite to flow without break APP
  if (!usersVerificationsResponse.ok) {
    sendHttpError({
      baseName: 'Get users id verifications',
      functionName: 'getUsersIdVerifications',
      method: 'GET',
      statusCode: usersVerificationsResponse.status,
      url,
    });

    throw new Error('Failed to fetch data');
  }

  const usersVerificationsResponseData = await usersVerificationsResponse.json() as UsersIdVerificationCollectionResponse;

  return userIdVerificationAdapter(usersVerificationsResponseData);
}
