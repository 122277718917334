import userAdapter from '@/features/user/requests/adapters/user-adapter';
import getUsersIdVerifications from '@/features/user/requests/get-users-id-verifications';
import { type UserResponse } from '@/features/user/types/user-response-types';
import { type LoggedUser } from '@/features/user/types/user-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

export default async function getLoggedUserData(userBearerToken: string): Promise<LoggedUser | null> {
  const headers: HeadersInit = { Authorization: `Bearer ${userBearerToken}` };
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/me`;

  const getLoggedUserResult = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/me`, {
    cache: 'no-store',
    headers,
  });

  if (!getLoggedUserResult.ok) {
    sendHttpError({
      baseName: 'Get logged user data',
      functionName: 'getLoggedUserData',
      method: 'GET',
      requestHeaders: headers,
      statusCode: getLoggedUserResult.status,
      url,
    });

    return null;
  }

  const userMissingProfileFieldsResponse = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/UserStatus?fields=user_missing_items`, {
    cache: 'no-store',
    headers,
  });

  let userMissingFields: string[] = [];

  if (userMissingProfileFieldsResponse.ok) {
    userMissingFields = (await userMissingProfileFieldsResponse.json() as { user_missing_items: string[] }).user_missing_items;
  } else {
    sendHttpError({
      baseName: 'Get logged user data',
      functionName: 'getLoggedUserData',
      method: 'GET',
      requestHeaders: headers,
      statusCode: getLoggedUserResult.status,
      url,
    });
  }

  const userResponse = await getLoggedUserResult.json() as UserResponse;
  const loggedUser = userAdapter(userResponse);

  const userVerificationsResponse = await getUsersIdVerifications([loggedUser.id]);
  loggedUser.verifications.id = userVerificationsResponse[loggedUser.id];

  return {
    ...loggedUser,
    missingFields: userMissingFields,
  };
}
